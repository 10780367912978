@use '@omm.agency/irecomponents/dist/assets/styles/globals' as *;
@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

html {
  font-size: 80%;
}

body {
  background-color: $color-black;
  color: $color-white;
  overflow: hidden;
}
