@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

.termsForm {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.infoBox {
  width: 100vw;
  max-width: rem(550);
  margin: auto;
  padding: rem(50) rem(30);
  box-sizing: border-box;
  border: 2px solid $color-white;
  display: flex;
  flex-direction: column;
  height: rem(390);
  > p,
  h2 {
    text-align: center;
  }
}

.info {
  text-decoration: underline;
  cursor: pointer;
}

.button {
  margin: 0 rem(30);
}

.scrollWrap {
  flex: 1 0 0;
  margin: $paddingXl 0 $paddingXxl;
  :first-child {
    margin-top: 0;
  }
}

.scrollContent {
  padding: 0 rem(28);
}
