@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

.wrap {
  display: flex;
  margin-bottom: rem(42);

  > p {
    margin: rem(-7) 0 0 rem(16);
    display: block;
    text-align: left;
    flex: 1 0 0;
  }
}
