@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

.howToPlayWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 75vh;

  .headerWrap {
    position: relative;
    z-index: 2;
  }

  p {
    max-width: rem(400);
    text-align: center;
  }

  .howToPlaySteps {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .howToPlayStepsInner {
    display: flex;
    width: 100%;
    column-gap: $paddingMd;
  }

  .howToPlayStep {
    width: rem(300);
    border: 1px solid $color-white;
    color: $color-white;
    text-align: center;
    padding: rem(44) $paddingMd;
    margin: $paddingXl 0 $paddingLg;
    opacity: 0.25;
    background-color: $color-black;

    h4 {
      letter-spacing: rem(2);
    }

    .step {
      display: inline-block;
      font-size: $headerFontSize2;
      font-family: $fontCondBold;
      margin-bottom: $paddingMd;
    }
  }

  .howToPlayBottomWrap {
    width: rem(1002);
    height: rem(200);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .howToPlayProgressWrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: rem(5);
    background-color: $color-dune;
    margin-bottom: rem(120);

    .howToPlayProgressBar {
      height: 100%;
      background-color: $color-emerald;
    }
  }

  .button {
    position: absolute;
    bottom: rem(80);
    // transform: translateY('-200px');
  }
}

.backgroundOutlineText {
  top: 22%;
  left: 12%;
  -webkit-text-stroke-color: rgba($color-white, 0.25);
}
