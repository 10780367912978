@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

.gameOverWrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: $paddingMd;
    text-align: center;
  }

  h3 {
    margin: rem(130) 0 $paddingLg;

    span {
      color: $color-emerald;
    }
  }

  p {
    color: $color-silver;
    width: rem(550);
    text-align: center;
    margin-bottom: rem(160);
  }

  .backgroundOutlineText {
    white-space: nowrap;
    top: rem(90);
    -webkit-text-stroke-color: rgba($color-white, 0.25);
  }
}
