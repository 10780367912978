@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

.wrap {
  box-sizing: border-box;
  height: 100vh;
  max-height: 550px;
  padding: rem(100) 0 rem(72) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: rem(680);
  margin: auto;
  h1 {
    text-align: center;
    margin-bottom: $paddingXl;
  }
  p {
    text-align: center;
    margin-bottom: rem(40);
  }
  .input {
    width: 100%;
    margin-bottom: rem(40);
  }
}

.formInputs {
  flex: 1 0 0;
}

.errorIcon,
.successIcon {
  box-sizing: border-box;
  width: rem(60);
  height: rem(60);
  margin: auto;
  border-radius: 50%;
  padding: rem(19.5);
  margin-bottom: rem(31);
  display: block;
  svg {
    width: 100%;
    height: 100%;
    path {
      stroke-width: rem(12);
    }
  }
}

.errorIcon {
  background: $color-valencia;
  margin-left: auto;
  margin-right: auto;
  display: block;
  line {
    stroke-width: rem(6);
  }
}

.successIcon {
  background: $color-emerald;
  margin-top: rem(280);
}

.exitButton {
  display: flex;
  position: absolute;
  top: 0;
  z-index: 110;
  transform: translate3d(rem(60), rem(28), 0);
  font-family: $fontCondBold;
  text-transform: uppercase;
}

.appFrameExitLabel {
  margin-left: $paddingMd;
  font-size: $fontSizeLg;
  line-height: $headerLineHeight6;
  letter-spacing: rem(2);
  margin-top: rem(2);
}

.formStatus {
  height: 100vh;
  max-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: auto;
  }
}
