@use 'sass:math';
@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

.homeWrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
  }

  .homeContent {
    position: absolute;
    margin-top: calc(50vh + rem(50));
    padding-top: $paddingLg;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;

    p {
      max-width: rem(600);
      text-align: center;
      padding: 0 0 rem(36);
      margin: 0 0 rem(140);
    }
  }

  .homeVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    z-index: -1;
    object-fit: cover;
  }
}
