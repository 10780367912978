@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

$_width: rem(1500);
$_height: rem(600);

$_videoHeight: rem(600);

.videoPageWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .videoTitleWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $_width;
    height: $_height;
    background-size: cover;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: rgba($color-black, 0.5);
    }

    .videoOverlayWrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      z-index: 2;
    }

    h2 {
      text-align: center;
    }

    p {
      text-align: center;

      &.tournament {
        position: absolute;
        margin-top: rem(-122);
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .videoWrap {
    position: absolute;
    align-self: center;
    z-index: -1;

    .video {
      width: auto;
      height: $_videoHeight;
    }
  }

  .answerIcon {
    margin-top: auto;
  }

  .answerHeader {
    width: rem(640);
    text-align: center;
    margin: $paddingLg 0;
  }

  .answerText {
    width: rem(740);
    text-align: center;
    margin-bottom: auto;
  }

  .answerVideoOverlayButton {
    position: absolute;
    bottom: rem(-102);
  }
}

.initialHeader {
  position: absolute;
  top: rem(440);
  z-index: 2;
}

.backgroundOutlineTextWrap {
  position: absolute;
  width: rem(1400);
  top: rem(170);

  &.initial {
    top: rem(300);
  }

  .backgroundOutlineText {
    -webkit-text-stroke-color: rgba($color-white, 0.25);
  }
}

.questionAnswerWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: $_videoHeight;
  z-index: 1;

  .quetsionAnswerHeader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(100);
    margin-top: rem(-50);

    h2 {
      position: absolute;
      margin-top: 0;
      text-align: center;
    }
  }

  .backgroundOutlineTextWrap {
    position: absolute;
    width: rem(2200);
  }

  .videoReplay {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    margin-bottom: rem(-90);

    p {
      margin: $paddingSm 0 0;
    }
  }

  .questionOptions {
    display: flex;
    column-gap: rem(50);
    align-items: center;
    justify-content: center;
    margin-top: rem(160);

    .questionOrLabel {
      margin: 0;
    }
  }

  .questionOption {
    width: rem(440);
    box-sizing: border-box;
    border: 1px solid $color-white;
    background-color: $color-black;
    padding: $paddingXxl $paddingLg;
    cursor: pointer;

    h4,
    p {
      text-align: center;
    }
  }
}
