@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

.wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: rem(700);
  margin: auto;
  padding-top: rem(250);
  h2 {
    font-size: $headerFontSize0;
    line-height: $headerLineHeight0;
  }
  p {
    max-width: rem(380);
    text-align: center;
    margin-top: rem(60);
  }
}

.button {
  margin-top: rem(147);
}

.bgText {
  top: 28%;
}
