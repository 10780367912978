@use '@omm.agency/irecomponents/dist/assets/styles/base' as *;

.yourScoreWrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  .yourScoreValue {
    color: $color-emerald;
    margin: $paddingMd 0 $paddingLg;
  }

  .button {
    position: absolute;
    bottom: rem(80);
  }
}

.questionsWrap {
  display: flex;
  flex-direction: row;
  column-gap: $paddingLg;
  padding: $paddingXxl 0 $paddingXxl;

  .question {
    .questionInner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: rem(265);
      height: rem(370);
      padding: $paddingMd;
      box-sizing: border-box;
      border: rem(2) dashed $color-scorpion;
    }

    &.isDone {
      & + .question {
        .nextLabel {
          opacity: 1;
        }
      }
    }

    .thumbnail {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center center;
    }

    .thumbnailOverlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;

      &.isCorrect {
        background-color: $color-emerald;
      }

      &.isIncorrect {
        background-color: $color-valencia;
      }
    }

    h4 {
      text-align: center;
      margin-top: $paddingSm;
      color: $color-white;
    }

    .nextLabel {
      opacity: 0;
      color: $color-silver;
    }
  }
}
